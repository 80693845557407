import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestUserUninstall = createAction();
export const responseUserUninstall = createAction();




export function getUserUnindtallData(params){
  return (dispatch) => {
      dispatch(requestUserUninstall([]));
      httpClient.call('ANALYTICS', '/get_user_uninstall_data', params, {method: 'GET', headers: {}}).then(function(response){
          dispatch(responseUserUninstall(response));
      }, function(error){
          console.log('error', error);
          dispatch(responseUserUninstall(error));
      });
  }
}
