import { logout } from '../../utils/common';
import Session  from '../../utils/session';
import config from '../../config';
import axios from 'axios';

let service = {};
if (process.env.REACT_APP_MODE === "test") {
  service =  {
      "CONFIG" : {
        "HOST": "https://testapi.ninjafocus.com/config",
        "API_KEY": config.REACT_APP_API_KEY,
      },
    }
}else if(process.env.REACT_APP_MODE === "stage" || process.env.REACT_APP_MODE === "uat") {
   service =  {
      "CONFIG" : {
        "HOST": "https://uatapi.ninjafocus.com/config",
        "API_KEY": config.REACT_APP_API_KEY,
      },
    }
}else if(process.env.REACT_APP_MODE === "local") {
   service =  {
      "CONFIG" : {
        "HOST": "http://127.0.0.1:3005",
        "API_KEY": config.REACT_APP_API_KEY,
      },
    }
}else if(process.env.REACT_APP_MODE === "prod") {
   service =  {
      "CONFIG" : {
        "HOST": "https://api.ninjafocus.com/config",
        "API_KEY": config.REACT_APP_API_KEY,
      },
    }
}else {
  service = {
    "CONFIG" : {
      "HOST": "https://testapi.ninjafocus.com/config",
      "API_KEY": config.REACT_APP_API_KEY,
    },
  }
}

const getSessionConfig = ()=>{
  let configSession = Session.getSession('config');
  if(configSession !== undefined){
    var serviceData = {};
    configSession.SERVICES.forEach((item)=>{
      serviceData[item.SERVICE] = item;
    });
  }
  return serviceData;
}


let defaultConfig = {
    API_URL: "",
    SERVICE: service,
    HEADERS: {
        "Content-Type": "application/json; charset=utf-8",
    }

};





let httpClient = {

    /*
    * setConfig method to set http client config in defaultConfig
    * @param configObject ConfigObject use as a global variable and access it in getConfig
    * combine default config and param config
    */
    setConfig(configObject){
        defaultConfig = Object.assign(defaultConfig, configObject);
    },

    /*
    * getConfig method to get http client config
    * return http client config object
    */
    getConfig(){
      return defaultConfig;
    },


    setHeaderPassword(password) {
      defaultConfig['HEADERS']['password'] = password;
    },


    unsetHeaderPassword() {
      delete defaultConfig['HEADERS']['password'];
    },


    /*
    * call method to call api request
    * @param endPoint EndPoint to add in cinfig fileUrl
    * @param data Data to send this object on api requests
    * @param options Options use to set request method and headers
    * return primise
    */

    call(service,endPoint, data, options, processCallBack){

        processCallBack = typeof processCallBack == 'function' ? processCallBack : function(){}
        return new Promise(function(resolve, reject) {
              // get http client config
              let httpConfig = httpClient.getConfig();
              let method = options && options['method'] ? options['method'] : 'GET';

              // check header exist in option
              if(typeof options['headers'] === 'undefined'){
                // added headers in option object
                options['headers'] = {}
              }

              // combine default headers, config headers and api key
              httpConfig['SERVICE'] = Object.assign(httpConfig['SERVICE'], getSessionConfig());
              let servicesApiKey = {};
               if(httpConfig['SERVICE'][service] && httpConfig['SERVICE'][service].API_KEY){
                servicesApiKey = {'X-API-KEY': httpConfig['SERVICE'][service].API_KEY }
               }
               let servicesHOST = '';
               if(httpConfig['SERVICE'][service] && httpConfig['SERVICE'][service].HOST){
                servicesHOST = httpConfig['SERVICE'][service].HOST;
               }

              let apps = Session.getStorageData('apps');

              let country = Session.getStorageData('country');
              if(country && country.name){
                options['headers']['country'] = country.name
              }else{
                options['headers']['country'] = ""
              }

              if(apps){
                options['headers']['apps'] = apps
              }

              let dates = Session.getStorageData('dates');
              if(dates && dates.startDate && dates.endDate){
                options['headers']['start-date'] = dates.startDate;
                options['headers']['end-date'] = dates.endDate;
              }

              options['headers'] = Object.assign(httpConfig['HEADERS'], options['headers'], servicesApiKey);

              // create request options object with method, body and headers
              let requestOptions = {};
              // get api url from config and combine API url and endPoint
              requestOptions['url'] = servicesHOST+config.REACT_APP_CURRENT_API_VERSION+endPoint;

              // check if request method is get then data will send in params option and if request method is post then data will send into data option
              if(method !== 'GET'){
                  requestOptions['data'] = data;
              }else{
                  requestOptions['params'] = data;
              }
              // add method in option
              requestOptions['timeout'] = 0;
              requestOptions['method'] = method;

              requestOptions['cache'] = "no-cache";

              // add headers in request option
              requestOptions['headers'] = options && options['headers'] ? options['headers'] : {};
              // add config in request option
              requestOptions['config'] = requestOptions['headers'];
              requestOptions['onUploadProgress'] = function (progressEvent) {
                // Do whatever you want with the native progress event
                let percent = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                processCallBack(percent);
              }

              // send request with options.
              axios(requestOptions)
              .then(function (response) {
                  let responseData = response.data;
                  resolve(responseData);
                  if(responseData.status === 'success'){
                      resolve(responseData);
                  }else{
                    if(responseData.code === 103){
                          setTimeout(()=> {
                            Session.deleteCookie('session');
                            Session.deleteSessionData(function(){
                              logout()
                              window.location.href= config.NINJA_ADMIN_URL
                            })
                          }, 500);
                          reject(responseData);

                    }else{
                      reject(responseData);
                    }
                  }

              })
              .catch(function (error) {
                  //handle error
                  console.log(error);
                  if(error && error.response && error.response.status === 401){
                      Session.deleteCookie('session');
                      Session.deleteSessionData(function(){
                        setTimeout(()=> {

                          logout()
                          window.location.href= config.NINJA_ADMIN_URL
                        }, 500);
                      })
                      reject(error.response);
                  }else{
                      reject(error.response);
                  }
              });


        });
    },

    getAppConfig(){
      httpClient.call('CONFIG','/get', '', {method: 'GET', headers: {}}).then(function(response){
          Session.setSessionData(response.result, 'config');
      }, function(error){
        console.log(error)
      });
    },


    getAppLanguages(){
      httpClient.call('CONFIG','/get_allowed_for_admin_languages', '', {method: 'GET', headers: {}}).then(function(response){
          Session.setSessionData(response.result, 'languages');
      }, function(error){
        console.log(error)
      });
    }
}

// export http client
export default httpClient;
