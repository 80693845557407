import { createReducer } from 'redux-act';
import {} from './action';


const defaultState = {
    requestProcess: false,
};

const reducer = createReducer({

}, defaultState);



export default reducer