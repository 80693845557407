import { createReducer } from 'redux-act';
import { 
    requestGetRetentionOnweek,
    responseGetRetentionOnWeek
    } from './action';

const defaultState = {
    requestProcess: false,
};

const reducer = createReducer({

    [requestGetRetentionOnweek]: (state) => {
        return {
            requestProcess: true,
        };
    },

    [responseGetRetentionOnWeek]: (state, params) => {
        return {
            requestProcess: false,
            getRetentionOnWeeks: params,
        };
    },


}, defaultState);



export default reducer