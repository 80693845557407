import { createReducer } from 'redux-act';
import { 
    requestGetRetentionData,
    responseGetRetentionData,
    requestGetRetentionOnMonth,
    responseGetRetentionOnMonth
    } from './action';

const defaultState = {
    requestProcess: false,
};

const reducer = createReducer({

    [requestGetRetentionData]: (state) => {
        return {
            requestProcess: true,
        };
    },

    [responseGetRetentionData]: (state, params) => {
        return {
            requestProcess: false,
            getRetentionMonths: params,
        };
    },

    [requestGetRetentionOnMonth]: (state) => {
        return {
            requestProcess: true,
        };
    },

    [responseGetRetentionOnMonth]: (state, params) => {
        return {
            requestProcess: false,
            getRetentionOnMonth: params,
        };
    },


}, defaultState);



export default reducer