import { createReducer } from 'redux-act';
import {requestScreenAnalyticsReportData, responseScreenAnalyticsReportData} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestScreenAnalyticsReportData]: (state) => {
		return {
		  requestProcess: true,
			  data: [],
		};
	  },
	
	  [responseScreenAnalyticsReportData]: (state, params) => {
			return {
				requestProcess: false,
				data: params,
			};
		},


}, defaultState);



export default reducer
