import { createAction } from "redux-act";
import moment from "moment";
import store from "./../store";
import config from "../config";
import httpClient from "../services/http";
import session from "../utils/session";

export const requestLoaderVisibility = createAction();
export const requestSuccessMessage = createAction();
export const requestErrorMessage = createAction();

export const requestRefreshAppData = createAction();
export const showPageHeadLoaderData = createAction();

let apiInProgressColl = [];

export function refreshAppData() {
  store.dispatch(requestRefreshAppData(true));
  setTimeout(() => {
    store.dispatch(requestRefreshAppData(false));
  }, 500);
}

export function showLoading(value) {
  if (value) {
    apiInProgressColl.push(value);
  } else {
    apiInProgressColl.splice(value, 1);
  }

  if (apiInProgressColl.length > 0) {
    store.dispatch(showPageHeadLoaderData(true));
  } else {
    store.dispatch(showPageHeadLoaderData(false));
  }
}

export function SuccessToast(message) {
  store.dispatch(requestSuccessMessage(message));
  setTimeout(() => {
    store.dispatch(requestSuccessMessage(""));
  }, 3000);
}

export function ErrorToast(message) {
  store.dispatch(requestErrorMessage(message));
  setTimeout(() => {
    store.dispatch(requestErrorMessage(""));
  }, 3000);
}

export function Loader(isVisible, processBarPercent) {
  let param = {
    isVisible: isVisible,
    processBarPercent: processBarPercent,
  };
  store.dispatch(requestLoaderVisibility(param));
}

export function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function setHttpClientConfig() {
  return new Promise(function (resolve, reject) {
    let sessionData = session.getSessionData();
    let headers = {};
    if (sessionData && sessionData["jwtToken"]) {
      headers["Authorization"] = sessionData
        ? "Bearer " + sessionData["jwtToken"]
        : "";
    }
    headers["X-ADMIN-KEY"] = config.API_KEY;
    headers["X-API-KEY"] = config.API_KEY;
    headers["X-Content-Type-Options"] = "nosniff";
    headers["Accept"] = "application/json";
    headers["Content-Type"] = "application/json;charset=utf-8";
    httpClient.setConfig({
      API_URL: config.API_URL,
      HEADERS: headers,
    });
    resolve(true);
  });
}

export function logout() {
  session.deleteSessionData();

  var d = new Date();
  d.setTime(d.getTime());
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    "session=" + "" + ";" + expires + ";domain=" + config.NINJAFOCUS_DOMAIN;
}

export function cleanString(stringVal) {
  return stringVal
    .replace(/&amp;/g, "")
    .replace(/"/g, "&quot;")
    .replace(/#/g, "&num;")
    .replace(/'/g, "&apos;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

export const cardBg = [
  "bg-info",
  "bg-success",
  "bg-warning",
  "bg-danger",
  "bg-primary",
];

export const iconByName = {
  Users: "icon-user",
  "New Users": "icon-people",
  Sessions: "icon-clock",
  "Screen View": "cui-monitor",
};

export function createReferralCode() {
  return Math.random().toString(36).substring(2, 6).toUpperCase();
}

export function createCouponCode() {
  return Math.random().toString(36).substring(2, 8).toUpperCase();
}

export function msToTime(milliseconds) {
  let hours = milliseconds / (1000 * 60 * 60);
  let h = Math.floor(hours);

  //Get remainder from hours and convert to minutes
  let minutes = (hours - h) * 60;
  let m = Math.floor(minutes);

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - m) * 60;
  let s = Math.floor(seconds);

  return h + "hrs " + m + "min " + s + "sec";
}

export function convertToHours(milliseconds) {
  var hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  hour = Math.floor(minute / 60);
  return Math.ceil(hour / 10) * 10;
}

export function msToTimeFormat(milliseconds) {
  //let hours = milliseconds / (1000 * 60 * 60);
  let hours = milliseconds / 3600;
  let h = Math.floor(hours);
  h = h > 9 ? h : "0" + h;

  //Get remainder from hours and convert to minutes
  let minutes = (hours - h) * 60;
  let m = Math.floor(minutes);
  m = m > 9 ? m : "0" + m;

  return h + "h " + m + "m";
}

export function formatText(text) {
  if (typeof text !== "string") return "";
  text = text.replace(/_/g, " ");
  text = text.toLowerCase();
  text = text.charAt(0).toUpperCase() + text.slice(1);
  return text;
}

export function formatNumber(nStr) {
  // let n = parseFloat(nStr).toFixed(2)
  // return Number(n).toLocaleString('en');
  return nStr.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function getDayStartTime(options) {
  let date = moment().tz("America/Phoenix");
  if (options && options.date) {
    date = moment(options.date).tz("America/Phoenix");
  }
  date = date.set({ hour: 0, minute: 0, seconds: 0 });

  if (options && options.format) {
    date = date.format(options.format);
  }
  return date;
}

export function getMondayDate(date) {
  let convertedDate = moment(date)
    .tz("America/Phoenix")
    .startOf("isoWeek")
    .day("Monday");
  return convertedDate;
}

export function getMonthStartTime(options) {
  let date = moment(
    moment().tz("America/Phoenix").format("YYYY-MM-") + "01T07:00:00Z"
  );
  // date = date.set({ hour: 0, minute: 0, seconds: 0 });
  return date;
}

export function getMstCurrentTime(options) {
  return moment().tz("America/Phoenix");
}

export function FormatTitlePosLabel(s) {
  if (typeof s !== 'string') { return '' }
  s = s.replace("_", " ");
  s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  return s;
}
