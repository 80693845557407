import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestGetRetentionData = createAction();
export const responseGetRetentionData = createAction();

export const requestGetRetentionOnMonth = createAction();
export const responseGetRetentionOnMonth = createAction();


export function getRetentionMonths (params){
    return (dispatch) => {
        dispatch(requestGetRetentionData([]));
        httpClient.call('ANALYTICS', '/get_retention_months', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseGetRetentionData(response));
        }, function(error){
            dispatch(responseGetRetentionData(error));
        });
    }
}

export function getRetentionOnMonths(params){
    return (dispatch) => {
        dispatch(requestGetRetentionOnMonth([]));
        httpClient.call('ANALYTICS', '/get_customer_retention_by_month', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseGetRetentionOnMonth(response));
        }, function(error){
            dispatch(responseGetRetentionOnMonth(error));
        });
    }
}

