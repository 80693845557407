import crypt from '../services/crypt';

import Constants from '../utils/constants';

let Session = (function() {

  let storageInstance = function(){
    return window.localStorage;
  }

  let getSession = function(key) {
    key = key || 'session'
    var storagedata = storageInstance().getItem(key)
    let decryptData = crypt.decript(storagedata)
    if(typeof decryptData === 'string'){
      decryptData = JSON.parse(decryptData);
    }
    if(decryptData && decryptData.status === 403){
        deleteSessionData();
    }
    return decryptData;
  };

  let getSessionData = function(key) {
    key = key || 'session'
    // var storagedata = storageInstance().getItem(key)
    
    var storagedata = getCookie(key)
    let decryptData = crypt.decript(storagedata)
    if(typeof decryptData === 'string'){
      decryptData = JSON.parse(decryptData);
    }
    if(decryptData && decryptData.status === 403){
        deleteSessionData();
    }
    return decryptData;
  };

  let getStorageData = function(key){
      key = key || 'session'
      var storagedata = storageInstance().getItem(key)
      let decryptData = crypt.decript(storagedata)
      if(typeof decryptData === 'string'){
        decryptData = JSON.parse(decryptData);
      }
      if(decryptData && decryptData.status === 403){
          deleteSessionData();
      }
      return decryptData;
  }

  let isLoggedIn = function(){
      let session = getSessionData('session');
      return (session && session.jwtToken) ? true : false;
  }

  let setSessionData = function(sessionData, key) {
    let encryptData = crypt.encript(sessionData);
    key = key || 'session'
    storageInstance().setItem(key, encryptData)
  };


  let deleteSessionKey = function(key) {
    key = key || 'session'
    storageInstance().removeItem(key)
  };


  let deleteSessionData = function(cb){
    deleteCookie('session');
    storageInstance().removeItem('session');
    if (cb) {
      cb(true)
    }
  }


  // let setCookie = function(cname, cvalue, exdays) {
  //   var d = new Date();
  //   d.setTime(d.getTime() + (exdays*24*60*60*1000));
  //   var expires = "expires="+ d.toUTCString();
  //   document.cookie = cname + "=" + cvalue + ";" + expires+";domain="+Constants.NINJAFOCUS_DOMAIN;
  // }


  let getCookie = function(cname, cvalue, exdays) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + cname + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }else {
      return "";
    }
  }

  let deleteCookie = function(cname) {
    var d = new Date();
    d.setTime(d.getTime());
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + "" + ";" + expires+ ";domain="+Constants.NINJAFOCUS_DOMAIN;
  };

  return {
    isLoggedIn: isLoggedIn,
    getSession: getSession,
    getSessionData: getSessionData,
    getStorageData: getStorageData,
    setSessionData: setSessionData,
    deleteSessionData: deleteSessionData,
    deleteCookie: deleteCookie,
    deleteSessionKey: deleteSessionKey
  }

})();

export default Session;
