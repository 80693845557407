import {createAction} from 'redux-act';
import httpClient from '../../services/http';


export async function getAllCountries(){
    return httpClient.call('ANALYTICS', '/get_all_countries', {}, {method: 'GET', headers: {}}).
    then(function(response){
        return response
    }, function(error){
        return error
    });   
}