import {createAction} from 'redux-act';
import httpClient from '../../../services/http';

export const requestScreenAnalyticsReportData = createAction();
export const responseScreenAnalyticsReportData = createAction();

export function getScreenAnalyticsReport(params){
    return (dispatch) => {
        dispatch(requestScreenAnalyticsReportData([]));
        httpClient.call('ANALYTICS', '/get_screen_analytics_report', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseScreenAnalyticsReportData(response));
        }, function(error){
            dispatch(responseScreenAnalyticsReportData(error));
        });
    }

  }

