const Constants = {
    "IMAGE_PLATFORM": "IOS",
    "ALL": "ALL",
    "PILLAR": "PILLAR",
    "EMOTION": "EMOTION",
    "GRID": "GRID",
    "GRID_SQ": "GRID-SQ",
    "GRID_RT": "GRID-RT",
    "FULL_IMAGE": "fullImage",
    "LIST": "LIST",

    "VIDEO": "VIDEO",

    "LIST_LABEL": "List",
    "GRID_LABEL": "Grid",
    "GRID_SQ_LABEL": "Square Grid",
    "GRID_RT_LABEL": "Rectanlge Grid",



    "MEDITATION": "MEDITATION",
    "MUSIC": "MUSIC",
    "WRITING": "WRITING",
    "GAMES": "GAMES",
    "YOGA": "YOGA",

    'AUDIO': 'AUDIO',


    "PAID": "PAID",
    "FREE": "FREE",
    "MIDDLE": "MIDDLE",
    "BOTTOM": "BOTTOM",
    "TOP": "TOP",
    "BELOW_THUMB": "BELOW_THUMB",
    "PERCENTAGE": "PERCENTAGE",
    "OFF": "OFF",

    "ACTIVE": "ACTIVE",
    "INACTIVE": "INACTIVE",

    //PLAN TYPE
    "YEARLY": "YEARLY",
    "LIFETIME": "LIFETIME",
    "MONTHLY": "MONTHLY",

    "FACEBOOK": "FACEBOOK",
    "GOOGLE": "GOOGLE",
    "INSTAGRAM": "INSTAGRAM",

    "HIGH": "HIGH",
    "MEDIUM": "MEDIUM",
    "LOW": "LOW",

    // FEEDBACK
    "OPEN": "OPEN",
    "CLOSE": "CLOSE",


    "VERIFIED": "Verified",
    "PENDING": "Pending",


    "SOMETHING_WRONG": "Something went wrong! Please try again",


    "MAX_DISCOUNT_PERCRNTAGE": 100,
    "MAX_DISCOUNT_OFF": 399,

    "NINJAFOCUS_DOMAIN": "ninjafocus.com",


    "IN_HOUSE": "IN_HOUSE",
    "S3": "S3",

    "PROMOTIONAL": "PROMOTIONAL",
    "AFFILATED": "AFFILATED",
    "FREE_CODE": "FREECODE",
    "SOCIAL_INVITE": "SOCIAL_INVITE",

    "IMAGE": "IMAGE",
    "ENGLISH": "ENGLISH",

    'PLACEHOLDER_TROPHY_ACTUAL_IMG': "placeholder/imgTrophy3x.png",
    'US_REGIONS': [
        "US", "OTHERS"
    ],

    "ANALYTICS_REPORT": {
        "NEW_USERS": "NEW_USERS",
        "EXPLORATORY_USERS": "EXPLORATORY_USERS",
        "ACTIVE_USER_PAID": "ACTIVE_USER_PAID",
        "ACTIVE_USER_FREE": "ACTIVE_USER_FREE",
        "LAPSED_USER_PAID": "LAPSED_USER_PAID",
        "LAPSED_USER_NEVER_PAID": "LAPSED_USER_NEVER_PAID",
        "UNINSTALL_USER_AFTER": "UNINSTALL_USER_AFTER",
        "RE_ENGAGEMENT_USER_FREE": "RE_ENGAGEMENT_USER_FREE",
        "RE_ENGAGE_USER_PAID": "RE_ENGAGE_USER_PAID",
        "CONVERTED_FREE": "CONVERTED_FREE",
        "UNINSTALLED_AFTER_RELEASE": "UNINSTALLED_AFTER_RELEASE",
        "UNINSTALLED_BEFORE_RELEASE": "UNINSTALLED_BEFORE_RELEASE",
        "UNINSTALLED_BEFORE_29_SEPT": "UNINSTALLED_BEFORE_29_SEPT",
        "UNINSTALLED_AFTER_USAGE": "UNINSTALLED_AFTER_USAGE",
        "CURRENT_VERSION_USER": "CURRENT_VERSION_USER",
        "PREVIOUS_VERSION_USER": "PREVIOUS_VERSION_USER",
        "OPENED_PAYMENT_SUBSCRIPTION": "OPENED_PAYMENT_SUBSCRIPTION",
        "ACTIVE_USER_PAID_TO_FREE": "ACTIVE_USER_PAID_TO_FREE",
    },


    "SERVICE": {
        "USER": "USER",
        "ANALYTICS": "ANALYTICS",
        "PILLAR": "PILLAR",
    },

}

export default Constants;
