import { createReducer } from 'redux-act';
import {requestUserUninstall, responseUserUninstall} from './action';


const defaultState = {
	requestProcess: false,
};

const reducer = createReducer({

	[requestUserUninstall]: (state) => {
    return {
      requestProcess: true,
	  	data: [],
    };
  },

  [responseUserUninstall]: (state, params) => {
		return {
			requestProcess: false,
			data: params,
		};
	},


}, defaultState);



export default reducer
