import React from "react";

const UserList = React.lazy(() => import("./views/UserList"));
const Metrics = React.lazy(() => import("./views/Metrics"));
const DailyMetrics = React.lazy(() => import("./views/Metrics/DailyMetrics"));
const SocketTest = React.lazy(() => import("./views/temp/SocketTest"))
const AudienceOverview = React.lazy(() => import("./views/AudienceOverview"));
const Analytics = React.lazy(() => import("./views/Analytics"));

//new dashboard
const NewDashboard = React.lazy(() => import("./views/NewDashboard"));

const MonthlySignup = React.lazy(() => import("./views/KPIS/MonthlyRetention"))

const MonthlyRetention = React.lazy(() => import("./views/KPIS/MonthlyRetentionView"))

const WeeklyRetention = React.lazy(() => import("./views/KPIS/WeeklyRetention"))

const KPIScreen = React.lazy(() => import("./views/KPIS/KpiGraph"))


// new monthly retention
// new weekly retention
// // new weekly retention
// const NewWeeklyRetention = React.lazy(() => import("./views/WeeklyRetention"))

// Analytics Metrics
const AnalyticsReports = React.lazy(() => import("./views/AnalyticsReports/AnalyticsReports"));
// new retention
const NewRetention = React.lazy(() => import("./views/NewRetention/NewRetention"));
const UserWeeklyInsight = React.lazy(() => import("./views/UserWeeklyInsight/UserWeeklyInsight"))
const UserWeeklyInstall = React.lazy(() => import("./views/UserWeeklyInstall/UserWeeklyInstall"))
const UserWeeklyPaid = React.lazy(() => import("./views/UserWeeklyPaid/UserWeeklyPaid"))

const routes = [{
  path: "/",
  exact: true,
  name: "Home",
  roles: ["ADMIN", "ANALYTICS"]
},
{
  path: "/dashboard",
  name: "Dashboard",
  component: NewDashboard,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/user-list",
  name: "Dashboard",
  component: UserList,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/audience-overview",
  name: "Audience Overview",
  component: AudienceOverview,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/metrics",
  name: "Metrics",
  component: Metrics,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/daily-metrics",
  name: "Daily Metrics",
  component: DailyMetrics,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/analytics-funnel",
  name: "Analytics Funnel",
  component: Analytics,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/monthly-retention",
  name: "Monthly Retention",
  component: MonthlyRetention,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/weekly-retention",
  name: "Weekly Retention",
  component: WeeklyRetention,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/retention",
  name: "New Retention",
  component: NewRetention,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/retention-kpi",
  name: "Retention KPI",
  component: KPIScreen,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/monthly-signup",
  name: "Monthly Sign-Up",
  component: MonthlySignup,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/retention-monthly",
  name: "Monthly Retention",
  component: MonthlyRetention,
  roles: ["ADMIN", "ANALYTICS"],
}, {
  path: "/retention-weekly",
  name: "Weekly Retention",
  component: WeeklyRetention,
  roles: ["ADMIN", "ANALYTICS"],
}, {
  path: "/socket-test",
  name: "Socket Test",
  component: SocketTest,
  roles: ["ADMIN", "ANALYTICS"],
},{
  path: "/analytics-reports",
  name: "AnalyticsReports",
  component: AnalyticsReports,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/user-insight",
  name: "User Weekly Insight",
  component: UserWeeklyInsight,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/user-install",
  name: "User Weekly Install",
  component: UserWeeklyInstall,
  roles: ["ADMIN", "ANALYTICS"],
},
{
  path: "/user-paid",
  name: "User Weekly Paid",
  component: UserWeeklyPaid,
  roles: ["ADMIN", "ANALYTICS"],
},
];

export default routes;
