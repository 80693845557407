export const _local = {
    "APP_ENV": "local",
    "APP_PUBLIC_URL": "http://localhost:3000",
    "API_URL": "http://localhost:3007",
    "NINJA_ADMIN_URL": "https://admin.ninjafocus.com",
    "NINJA_WEB_LINK": "https://test.ninjafocus.com",
    "API_KEY": "64F23711B5E632D85E4681EA63222",
    "NINJAFOCUS_DOMAIN": "*",
    "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "SOCKET_REFRESH_TIME":10000,
    "REACT_APP_FACEBOOK_KEY": 764849677325932,
    "REACT_APP_INSTAGRAM_KEY": '0b55a6ec29ca191ee5c0c6b690ea2878',
    "REACT_APP_API_KEY": 'A78D5E1FD24C426596A3F28432693',
    "REACT_APP_CURRENT_API_VERSION": '/v12'
}

export const _dev = {
    "APP_ENV": "local",
    "APP_PUBLIC_URL": "http://10.1.0.30:3008",
    "API_URL": "http://10.1.0.30:3007",
    "NINJA_ADMIN_URL": "https://ninjadevadmin.gate6.com",
    "NINJA_WEB_LINK": "https://test.ninjafocus.com",
    "API_KEY": "196E5929FF6CC4525FF25EBC66FC1",
    "NINJAFOCUS_DOMAIN": "gate6.com",
    "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "SOCKET_REFRESH_TIME": 10000,
    "REACT_APP_FACEBOOK_KEY": 764849677325932,
    "REACT_APP_INSTAGRAM_KEY": '0b55a6ec29ca191ee5c0c6b690ea2878',
    "REACT_APP_API_KEY": 'A78D5E1FD24C426596A3F28432693',
    "REACT_APP_CURRENT_API_VERSION": '/v12'
}

export const _test = {
  "APP_ENV": "local",
  "APP_PUBLIC_URL": "https://testanalytics.ninjafocus.com/",
  "API_URL": "https://testapi.ninjafocus.com/analytics",
  "API_USER_URL": "https://testapi.ninjafocus.com/user",
  "SOCKET_URL": "http://testapi.ninjafocus.com:3007",
  "NINJA_ADMIN_URL": "https://testadmin.ninjafocus.com",
  "NINJA_WEB_LINK": "https://test.ninjafocus.com",
  "API_KEY": "196E5929FF6CC4525FF25EBC66FC1",
  "NINJAFOCUS_DOMAIN": "ninjafocus.com",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "SOCKET_REFRESH_TIME": 10000,
  "REACT_APP_FACEBOOK_KEY": 764849677325932,
  "REACT_APP_INSTAGRAM_KEY": '0b55a6ec29ca191ee5c0c6b690ea2878',
  "REACT_APP_API_KEY": 'A78D5E1FD24C426596A3F28432693',
  "REACT_APP_CURRENT_API_VERSION": '/v12'
}
export const _stage = {
  "APP_ENV": "local",
  "APP_PUBLIC_URL": "https://uatanalytics.ninjafocus.com",
  "API_URL": "https://uatapi.ninjafocus.com/analytics",
  "API_USER_URL": "https://uatapi.ninjafocus.com/user",
  "SOCKET_URL": "https://uatapi.ninjafocus.com",
  "NINJA_ADMIN_URL": "https://uatadmin.ninjafocus.com",
  "NINJA_WEB_LINK": "https://test.ninjafocus.com",
  "API_KEY": "196E5929FF6CC4525FF25EBC66FC1",
  "NINJAFOCUS_DOMAIN": "ninjafocus.com",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "SOCKET_REFRESH_TIME": 10000,
  "REACT_APP_FACEBOOK_KEY": 764849677325932,
  "REACT_APP_INSTAGRAM_KEY": '0b55a6ec29ca191ee5c0c6b690ea2878',
  "REACT_APP_API_KEY": 'A78D5E1FD24C426596A3F28432693',
  "REACT_APP_CURRENT_API_VERSION": '/v12'
}

export const _production = {
  "APP_ENV": "local",
  "APP_PUBLIC_URL": "https://analytics.ninjafocus.com",
  "API_URL": "https://api.ninjafocus.com/analytics",
  "API_USER_URL": "https://api.ninjafocus.com/user",
  "SOCKET_URL": "https://api.ninjafocus.com",
  "NINJA_ADMIN_URL": "https://admin.ninjafocus.com",
  "NINJA_WEB_LINK": "https://www.ninjafocus.com",
  "API_KEY": "196E5929FF6CC4525FF25EBC66FC1",
  "NINJAFOCUS_DOMAIN": "ninjafocus.com",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "SOCKET_REFRESH_TIME": 10000,
  "REACT_APP_FACEBOOK_KEY": 764849677325932,
  "REACT_APP_INSTAGRAM_KEY": '0b55a6ec29ca191ee5c0c6b690ea2878',
  "REACT_APP_API_KEY": 'A78D5E1FD24C426596A3F28432693',
  "REACT_APP_CURRENT_API_VERSION": `/${process.env.REACT_APP_CURRENT_API_VERSION}`
}

const config = function getConfig(mode) {
  switch (mode) {
    case 'local':
      return _local;
    case 'dev':
      return _dev;
    case 'test':
      return _test;
    case 'stage':
      return _stage;
    case 'prod':
      return _production;
    default:
      return _local;
  }
}
export default config(process.env.REACT_APP_MODE);
