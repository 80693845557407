import {createAction} from 'redux-act';
import httpClient from '../../../services/http';


export const requestGetRetentionOnweek = createAction();
export const responseGetRetentionOnWeek = createAction();



export function getRetentionOnWeeks(params){
    return (dispatch) => {
        dispatch(requestGetRetentionOnweek([]));
        httpClient.call('ANALYTICS', '/get_customer_retention_by_week', params, {method: 'GET', headers: {}}).then(function(response){
            dispatch(responseGetRetentionOnWeek(response));
        }, function(error){
            dispatch(responseGetRetentionOnWeek(error));
        });
    }
}

